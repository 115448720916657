import React from "react"
import Layout from '../components/layout'
import SEO from '../components/seo'
import './index.scss'
import { useStaticQuery, graphql } from "gatsby"
import HTMLReactParser, { domToReact } from 'html-react-parser'

const ResourcePage = props => {
  const data = useStaticQuery(graphql`
    query {
      allPrismicHomepage {
        nodes {
          lang
          data {
            body {
              ... on PrismicHomepageBodyNavigationBar {
                id
                slice_type
              }
              ... on PrismicHomepageBodyHero {
                id
                slice_type
              }
              ... on PrismicHomepageBodySteps {
                id
                slice_type
              }
              ... on PrismicHomepageBodyHighlight {
                id
                slice_type
              }
              ... on PrismicHomepageBodyLinks {
                id
                slice_type
              }
              ... on PrismicHomepageBodyFooter {
                id
                slice_type
              }
            }
          }
        }
      }
    }
  `)
  const locale = props.pageContext.locale || 'en-us'
  return (
    <Layout locale={locale} pageContext={props.pageContext}
            navBarId={data.allPrismicHomepage.nodes.find(cur => cur.lang === locale).data.body.find(cur => cur.slice_type === 'navigation_bar').id}
            footerId={data.allPrismicHomepage.nodes.find(cur => cur.lang === locale).data.body.find(cur => cur.slice_type === 'footer').id}>
      <SEO title={props.pageContext.data.title.text} description={props.pageContext.data.description.text}/>
      <section className='hero is-primary'>
        <div className='hero-body'>
          <div className='container'>
            <div className='columns is-centered'>
              <div className='column is-three-quarters'>
                <h1 className='title is-spaced has-text-centered'>{props.pageContext.data.title.text}</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='section'>
        <div className='container'>
          <div className='columns is-centered'>
            <div className='column is-three-quarters'>
              <div className="content">
                {HTMLReactParser(props.pageContext.data.content.html, {
                  replace: (elem) => {
                    if (elem.name === 'p') {
                      if (((elem.attribs||{}).class||'').indexOf('block-img') !== -1) {
                        return <div className="box"><p className="has-text-centered">{domToReact(elem.children, {})}</p></div>
                      }
                    }
                  }
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ResourcePage
